import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import CourseSalesCapture from '../../containers/CourseSalesCapture';

const SalesPageTemplate = ({ data, location, pageContext }) => {
  const salesData = data.contentfulSalesPage;
  return <CourseSalesCapture location={location} data={salesData} />;
};

export const salesQuery = graphql`
  query($slug: String!) {
    contentfulSalesPage(slug: { eq: $slug }) {
      id
      logo {
        fluid(quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
        }
      }
      showMenu
      templatePrimaryColor {
        name
        value
      }
      templateSecondaryColor {
        name
        value
      }
      heroTitle
      heroSubtitle
      heroDescription
      heroImage {
        fluid(maxWidth: 601, quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
          sizes
        }
      }
      heroActionLink
      heroActionLabel
      heroActionColor {
        name
        value
      }
      heroActionFontColor
      heroBackground {
        fluid(quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
        }
      }
      heroMobileBackground {
        fluid(quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
        }
      }

      demonstrationVideoTitle
      demonstrationVideoSubtitle
      demonstrationVideoActionLink
      demonstrationVideoActionLabel
      demonstrationVideoImage {
        fluid(quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
        }
      }
      demonstrationVideoItems {
        videoLink
      }

      benefitTitle
      benefitSubtitle
      benefitActionLabel
      benefitActionLink
      benefitItems {
        slug
        description {
          description
        }
      }

      testimonialTitle
      testimonialVideoSubtitle
      testimonialItems {
        slug
      }

      testimonialVideoTitle
      testimonialVideoSubtitle
      testimonialVideoActionLabel
      testimonialVideoActionLink
      testimonialVideoItems {
        videoImage {
          fluid(quality: 100, toFormat: WEBP) {
            ...GatsbyContentfulFluid
          }
        }
        videoLink
      }

      teacherItems {
        slug
      }

      productTitle
      productSubtitle
      productTagWP

      faqTitle
      faqSubtitle
      faqItems {
        title
        content {
          content
        }
      }
    }
  }
`;

SalesPageTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    contentfulSalesPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      logo: PropTypes.object,
    }),
  }).isRequired,
};

export default SalesPageTemplate;
