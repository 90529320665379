import styled from 'styled-components';
import { animateUpDown } from '../../assets/css/animations';
import { device } from '../../theme';

export const AboutAreaWrap = styled.section`
  padding-top: ${({ noPadding }) => (noPadding ? '0px' : '100px')};
  padding-bottom: 100px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const AboutTextBox = styled.div`
  max-width: 570px;
  float: right;
  width: 100%;
  @media ${device.medium} {
    margin: 0 auto 30px;
    float: none;
  }
`;
export const AboutTextBoxAnimation = styled.div`
  position: relative;
  .animation_text {
    animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
    animation-name: ${animateUpDown};
    animation-iteration-count: infinite;
    &.one {
      animation-duration: 3.2s;
    }
    &.two {
      animation-duration: 3.8s;
    }
    &.three {
      animation-duration: 3.4s;
    }
    &.four {
      animation-duration: 3.4s;
    }
  }
  div {
    border-radius: 5px;
  }
`;

export const ImageBox1 = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    left: 20%;
    top: -80px;
    width: 220px !important;
    @media ${device.xlarge} {
      left: 20%;
      top: -85px;
    }
    @media ${device.large} {
      left: 20%;
      width: 200px !important;
    }
    @media ${device.medium} {
      left: 6%;
      top: -85px;
      width: 200px !important;
    }
    @media ${device.small} {
      left: 5%;
      top: -55px;
      width: 150px !important;
    }
    @media ${device.xsmall} {
      left: 5%;
      top: -55px;
      width: 140px !important;
    }
  }
`;
export const ImageBox2 = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    right: 20%;
    top: -103px;
    @media ${device.medium} {
      right: 5%;
      top: -103px;
    }
    @media ${device.small} {
      right: 5%;
      top: -65px;
      width: 130px !important;
    }
  }
`;
export const TextBox1 = styled.div`
  position: absolute;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 10px 20px;
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 700;
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  left: 20%;
  top: -103px;
  @media ${device.xlarge} {
    left: 20%;
    top: -85px;
  }
  @media ${device.large} {
    left: 20%;
    width: 200px !important;
  }
  @media ${device.medium} {
    left: 6%;
    top: -85px;
    width: 200px !important;
  }
  @media ${device.small} {
    left: 5%;
    top: -55px;
    width: 150px !important;
  }
  @media ${device.xsmall} {
    left: 5%;
    top: -60px;
    font-size: 16px !important;
    width: 150px !important;
    height: 100px !important;
  }
`;
export const TextBox2 = styled.div`
  position: absolute;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 10px 20px;
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 700;
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  right: 20%;
  top: -103px;
  @media ${device.medium} {
    right: 5%;
    top: -103px;
  }
  @media ${device.small} {
    right: 5%;
    top: -65px;
    font-size: 16px !important;
    width: 150px !important;
    height: 100px !important;
  }
`;
export const TextBox3 = styled.div`
  position: absolute;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 10px 20px;
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 700;
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  left: 20%;
  top: 95%;
  @media ${device.large} {
    bottom: 5px;
  }
  @media ${device.medium} {
    left: 5%;
  }
  @media ${device.small} {
    left: 5%;
    font-size: 16px !important;
    width: 150px !important;
    height: 100px !important;
  }
`;
export const TextBox4 = styled.div`
  position: absolute;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 10px 20px;
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 700;
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  right: 20%;
  left: auto;
  top: 100%;
  @media ${device.large} {
    right: 20%;
    top: 90%;
    width: 150px !important;
  }
  @media ${device.medium} {
    right: 5%;
    top: 90%;
    width: 150px !important;
  }
  @media ${device.small} {
    right: 15px;
    top: 85%;
    width: 140px !important;
  }
  @media ${device.xsmall} {
    right: 5%;
    top: 92%;
    font-size: 16px !important;
    width: 150px !important;
    height: 100px !important;
  }
`;
export const ImageBox3 = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    left: 20%;
    bottom: 55px;
    @media ${device.large} {
      bottom: 5px;
    }
    @media ${device.medium} {
      left: 5%;
      bottom: 65px;
    }
    @media ${device.small} {
      bottom: 30px;
      width: 120px !important;
      top: inherit;
    }
    @media ${device.small} {
      left: 5%;
      width: 110px !important;
    }
  }
`;
export const ImageBox4 = styled.div`
  .gatsby-image-wrapper {
    right: 20%;
    left: auto;
    top: 100%;
    @media ${device.large} {
      right: 20%;
      top: 90%;
      width: 150px !important;
    }
    @media ${device.medium} {
      right: 5%;
      top: 90%;
      width: 150px !important;
    }
    @media ${device.small} {
      right: 15px;
      top: 85%;
      width: 140px !important;
    }
    @media ${device.xsmall} {
      right: 5%;
      top: 90%;
      width: 120px !important;
    }
  }
`;

export const MainImageBox = styled.div`
  position: relative;
  z-index: 8;
  text-align: center;
  max-width: 570px;
  max-height: 350px;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 180px;
  @media ${device.large} {
    max-width: 400px;
    margin-bottom: 100px;
  }
  @media ${device.medium} {
    max-width: 570px;
    margin-bottom: 180px;
  }
  @media ${device.small} {
    max-width: 400px;
    margin-bottom: 100px;
  }
`;

export const VideoBtnWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const AboutImageBox = styled.div`
  position: relative;
  .animation_image {
    .gatsby-image-wrapper {
      animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
      animation-name: ${animateUpDown};
      animation-iteration-count: infinite;
    }
    &.one {
      .gatsby-image-wrapper {
        animation-duration: 3.2s;
      }
    }
    &.two {
      .gatsby-image-wrapper {
        animation-duration: 3.8s;
      }
    }
    &.three {
      .gatsby-image-wrapper {
        animation-duration: 3.4s;
      }
    }
    &.four {
      .gatsby-image-wrapper {
        animation-duration: 3.4s;
      }
    }
  }
  img {
    border-radius: 5px;
  }
`;

export const SectionBottom = styled.div`
  margin-top: 30px;
`;
